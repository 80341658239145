<template>
    <div class="content-div" v-if="priceDataVo && priceDataVo != ''">
        <div class="text">
            <span style="width: 100px; margin-right: 20px">等级</span>
            <span style="width: 200px; margin-right: 20px">等级名称</span>
            <span style="width: 100px; margin-right: 20px">等级值</span>
            <span style="width: 100px; margin-right: 20px">可兑商品数</span>
            <span style="width: 100px; margin-right: 20px">兑换频率</span>
            <span>图标</span>
            <Button style="margin-left: 100px" type="primary" @click="addPrice()" icon="ios-add"></Button>
        </div>
        <div class="input-div">
            <div class="answer-div" v-for="(item, index) in priceDataVo.pointsLevelBos" :key="index">
                <div style="display: flex; margin-bottom: 10px">
                    <span style="width: 100px; margin-right: 20px">{{ index + 1 }}</span>
                    <Input style="width: 200px; margin-right: 20px" placeholder="输入名称" v-model="item.levelName" />
                    <Input style="width: 100px; margin-right: 20px" type="Number" v-model="item.minPoints" placeholder="等级值" />
                    <Input style="width: 100px; margin-right: 20px" type="Number" v-model="item.obtainCount" placeholder="可兑商品数" />
                    <Select v-model="item.obtainRate" style="width: 120px; margin-right: 5px" placeholder="兑换频率" clearable>
                        <Option :value="item.dictKey" v-for="(item, index) in obtainRateList" :key="index">{{ item.dictValue }}</Option>
                    </Select>
                    <LiefengUpload
                        ref="imgData"
                        accept=".jpg,.png,.gif,.jpeg"
                        :format="['jpg', 'png', 'jpeg', 'gif']"
                        :defaultList="item.levelIcon"
                        :showView="true"
                        :target="'image'"
                        @success="
                            (target, val) => {
                                uploadSuccess(target, val, index)
                            }
                        "
                        v-if="showImage"
                    ></LiefengUpload>
                    <Button style="margin-left: 20px" v-if="priceDataVo.pointsLevelBos.length >= 2" type="error" @click="deletePrice(index)" icon="ios-trash"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: { LiefengUpload },
    props: {
        priceData: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            priceDataVo: {
                poolId: "",
                pointsLevelBos: [
                    {
                        levelName: "", //等级名称
                        minPoints: "", // 等级值
                        obtainCount: "", //可兑商品数
                        levelIcon: "", //选项图片
                        obtainRate: "",
                        levelSort: 0,
                    },
                ],
            },
            showImage: true,
            obtainRateList: [
                {
                    dictValue: "每月1次",
                    dictKey: "1",
                },
                {
                    dictValue: "每年1次",
                    dictKey: "2",
                },
                {
                    dictValue: "全程1次",
                    dictKey: "0",
                },
            ],
        }
    },
    methods: {
        sumbit() {
            if (!this.priceDataVo.pointsLevelBos || !this.priceDataVo.pointsLevelBos.length) {
                this.$Message.warning({
                    content: "等级设置至少需要一条",
                    background: true,
                })
                return
            }
            try {
                this.priceDataVo.pointsLevelBos.forEach((item, index) => {
                    if (!item.levelName || item.levelName == "") {
                        this.$Message.warning({
                            content: `等级设置第${index + 1}条等级名称不能为空`,
                            background: true,
                        })
                        throw new Error("条件不符合")
                    } else {
                        if (index == this.priceDataVo.pointsLevelBos.length - 1) {
                            this.$emit("success", {
                                ...this.priceDataVo,
                                pointsLevelBos: this.priceDataVo.pointsLevelBos.map(item => {
                                    return {
                                        ...item,
                                        levelIcon: item.levelIcon && item.levelIcon.length ? item.levelIcon[0].url : "",
                                    }
                                }),
                            })
                        }
                    }
                })
            } catch (e) {
                console.log("抛出错误", e)
            }
        },
        deletePrice(index) {
            this.priceDataVo.pointsLevelBos.splice(index, 1)
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
        },
        uploadSuccess(val, file, index) {
            if (file && file.url) this.priceDataVo.pointsLevelBos[index].levelIcon = [{ name: "", url: file.url }]
            else this.priceDataVo.pointsLevelBos[index].levelIcon = []

            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
        },
        addPrice() {
            this.priceDataVo.pointsLevelBos.push({
                levelName: "", //选项名称
                minPoints: "",
                obtainCount: "", //选项库存
                levelIcon: "", //选项图片
                obtainRate: "",
                levelSort: 0,
            })
        },
    },
    watch: {
        priceData: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal == null) return
                this.priceDataVo = {
                    ...newVal,
                    pointsLevelBos:
                        newVal.pointsLevelBos && newVal.pointsLevelBos.length
                            ? newVal.pointsLevelBos.map(item => {
                                  return {
                                      ...item,
                                      levelIcon: item.levelIcon && item.levelIcon != "" ? [{ name: "", url: item.levelIcon }] : null,
                                      levelSort: item.levelSort && item.levelSort != "" ? item.levelSort : 0,
                                  }
                              })
                            : [
                                  {
                                      levelName: "", //选项名称
                                      minPoints: "",
                                      obtainCount: "", //选项库存
                                      levelIcon: "", //选项图片
                                      obtainRate: "",
                                      levelSort: 0,
                                  },
                              ],
                }
            },
        },
    },
}
</script>

<style lang="less" scoped>
.content-div {
    .btn-div {
        margin: 0px 0 0 20px;
    }
    .item {
        border-bottom: 1px solid #ccc;
        margin: 20px 0;
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    .item-div {
        display: flex;
        padding: 20px;
        .left {
            width: 30%;
            border-right: 1px solid #ccc;
            padding-right: 20px;
        }
        .right {
            flex: 1;
            padding-left: 20px;
            .form-content {
                margin-top: 20px;
                border-bottom: 1px solid rgba(#ccc, 0.5);
                display: flex;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                .right-form {
                    flex: 1;
                }
                .right-btn {
                    margin-left: auto;
                }
            }
        }
    }
    .text {
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #287aad;
        display: flex;
        .right-btn {
            margin-left: auto;
        }
    }
    .input-div {
        // display: flex;
    }
    .answer-div {
        // margin-left: 20px;
    }
    .validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }
}
</style>